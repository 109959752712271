.vision {
  &-text {
    position: relative;
    background-color: $white;
    padding: 1.875rem;
    a:not(.text-link) {
      color: $blue;
      font-weight: 600;
      text-decoration: underline;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  &-image {
    position: relative;
    min-height: 240px;

    &__overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1.875rem;
      z-index: 9;

      a {
        @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 1.1, 1.1);
        color: $white;
        font-weight: 300;
        text-decoration: none;
        border-bottom: 1px solid transparent;;
        @include transition(0.3s);

        &:after {
          content: "›";
          margin-left: 0.35rem;
          line-height: inherit;
        }

        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }
  }
}

.vision-wrapper {
  display: grid;
  display: -ms-grid;
  -ms-grid-rows: 6fr;

  flex: 1 auto;

  > * {

    &:nth-of-type(1) {
      grid-column: 1;
      grid-row: 2;
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }

    &:nth-of-type(2) {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }
  }
}

@include media-breakpoint-up(xl) {
  .vision {
    &-image {
      height: 100%;
    }
  }

  .vision-wrapper {
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 6fr 1.875rem 6fr;

    > * {

      &:nth-of-type(1) {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-of-type(2) {
        grid-column: 2;
        grid-row: 1;
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
    }
  }
}