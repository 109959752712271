blockquote {
  position: relative;
  margin: 1.75rem 2rem 1.75rem 2rem;
  display: inline-block;
  @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.25, 1.25);
  font-weight: 300;
  color: $blue;
  text-align: center;

  p {
    margin: 0;
    &:first-of-type {
      &:before {
        content: "“";
        margin-right: 0.5rem;
        font-size: 42px;
        transform: translate(0px, 5px);
        line-height: 0;
        display: inline-block;
      }
    }
    &:last-of-type {
      &:after {
        content: "”";
        margin-left: 0.5rem;
        font-size: 42px;
        transform: translate(0px, 30px);
        line-height: 0;
        display: inline-block;
      }
    }
  }
}