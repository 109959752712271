.medewerkers-list-wrapper {
  > * {
    margin-bottom: 2rem;
  }
}

.medewerkers-list-item {
  position: relative;
  background-color: white;
  padding-top: 15px;

  .medewerkers-item {
    display: block!important;
    background-color: $white;

    .block-title {
      margin-bottom: 0rem;
    }

    header {
      position: relative;

      .medewerkers-item__image {
        overflow: hidden;
        border-radius: 50%;
        width: 73%;
        height: 73%;
        margin-left: auto;
        margin-right: auto;
        figure {
          @include transition(0.3s);
          padding-bottom: 100%;
        }
      }
    }

    section {
      padding: 1.25rem 1.875rem;
    }

    .post-date {
      @include fluid-type($min_width, $max_width, (15px/1.6), 15px, 1.25, 1.25);
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  a {
    color: $body-color;
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      font-weight: bold;
      figure {
        transform: scale(1.05);
      }
      &:after {
        opacity: 1;
      }
    }
  }

  &.highlighted {
    display: flex;
    align-items: center;
    padding: 1.875rem;
    background-color: $blue;
    @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 1.1, 1.1);
    font-weight: 200;

    a {
      color: $white;
    }

    span:after {
      content: "›";
      margin-left: 0.35rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .medewerkers-list-wrapper {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 6;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 6;
      }
      &:nth-of-type(13) {
        -ms-grid-column: 1;
        -ms-grid-row: 7;
      }
      &:nth-of-type(14) {
        -ms-grid-column: 3;
        -ms-grid-row: 7;
      }
      &:nth-of-type(15) {
        -ms-grid-column: 1;
        -ms-grid-row: 8;
      }
      &:nth-of-type(16) {
        -ms-grid-column: 3;
        -ms-grid-row: 8;
      }
      &:nth-of-type(17) {
        -ms-grid-column: 1;
        -ms-grid-row: 9;
      }
      &:nth-of-type(18) {
        -ms-grid-column: 3;
        -ms-grid-row: 9;
      }
      &:nth-of-type(19) {
        -ms-grid-column: 1;
        -ms-grid-row: 10;
      }
      &:nth-of-type(20) {
        -ms-grid-column: 3;
        -ms-grid-row: 10;
      }
      &:nth-of-type(21) {
        -ms-grid-column: 1;
        -ms-grid-row: 11;
      }
      &:nth-of-type(22) {
        -ms-grid-column: 3;
        -ms-grid-row: 11;
      }
      &:nth-of-type(23) {
        -ms-grid-column: 1;
        -ms-grid-row: 12;
      }
      &:nth-of-type(24) {
        -ms-grid-column: 3;
        -ms-grid-row: 12;
      }
      &:nth-of-type(25) {
        -ms-grid-column: 1;
        -ms-grid-row: 13;
      }
      &:nth-of-type(26) {
        -ms-grid-column: 3;
        -ms-grid-row: 13;
      }
      &:nth-of-type(27) {
        -ms-grid-column: 1;
        -ms-grid-row: 14;
      }
      &:nth-of-type(28) {
        -ms-grid-column: 3;
        -ms-grid-row: 14;
      }
      &:nth-of-type(29) {
        -ms-grid-column: 1;
        -ms-grid-row: 15;
      }
      &:nth-of-type(30) {
        -ms-grid-column: 3;
        -ms-grid-row: 15;
      }
      &:nth-of-type(31) {
        -ms-grid-column: 1;
        -ms-grid-row: 16;
      }
      &:nth-of-type(32) {
        -ms-grid-column: 3;
        -ms-grid-row: 16;
      }
      &:nth-of-type(33) {
        -ms-grid-column: 1;
        -ms-grid-row: 17;
      }
      &:nth-of-type(34) {
        -ms-grid-column: 3;
        -ms-grid-row: 17;
      }
      &:nth-of-type(35) {
        -ms-grid-column: 1;
        -ms-grid-row: 18;
      }
      &:nth-of-type(36) {
        -ms-grid-column: 3;
        -ms-grid-row: 18;
      }
      &:nth-of-type(37) {
        -ms-grid-column: 1;
        -ms-grid-row: 19;
      }
      &:nth-of-type(38) {
        -ms-grid-column: 3;
        -ms-grid-row: 19;
      }
      &:nth-of-type(39) {
        -ms-grid-column: 1;
        -ms-grid-row: 20;
      }
      &:nth-of-type(40) {
        -ms-grid-column: 3;
        -ms-grid-row: 20;
      }
      &:nth-of-type(41) {
        -ms-grid-column: 1;
        -ms-grid-row: 21;
      }
      &:nth-of-type(42) {
        -ms-grid-column: 3;
        -ms-grid-row: 21;
      }
      &:nth-of-type(43) {
        -ms-grid-column: 1;
        -ms-grid-row: 22;
      }
      &:nth-of-type(44) {
        -ms-grid-column: 3;
        -ms-grid-row: 22;
      }
      &:nth-of-type(45) {
        -ms-grid-column: 1;
        -ms-grid-row: 23;
      }
      &:nth-of-type(46) {
        -ms-grid-column: 3;
        -ms-grid-row: 23;
      }
      &:nth-of-type(47) {
        -ms-grid-column: 1;
        -ms-grid-row: 24;
      }
      &:nth-of-type(48) {
        -ms-grid-column: 3;
        -ms-grid-row: 24;
      }
    }
  }

  .medewerkers-list-item {
    &.highlighted {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .medewerkers-list-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 9;
        -ms-grid-row: 2;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(13) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(14) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
      &:nth-of-type(15) {
        -ms-grid-column: 9;
        -ms-grid-row: 3;
      }
      &:nth-of-type(16) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-of-type(17) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-of-type(18) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
      &:nth-of-type(19) {
        -ms-grid-column: 7;
        -ms-grid-row: 4;
      }
      &:nth-of-type(20) {
        -ms-grid-column: 9;
        -ms-grid-row: 4;
      }
      &:nth-of-type(21) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-of-type(22) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-of-type(23) {
        -ms-grid-column: 5;
        -ms-grid-row: 5;
      }
      &:nth-of-type(24) {
        -ms-grid-column: 7;
        -ms-grid-row: 5;
      }
      &:nth-of-type(25) {
        -ms-grid-column: 9;
        -ms-grid-row: 5;
      }
      &:nth-of-type(26) {
        -ms-grid-column: 1;
        -ms-grid-row: 6;
      }
      &:nth-of-type(27) {
        -ms-grid-column: 3;
        -ms-grid-row: 6;
      }
      &:nth-of-type(28) {
        -ms-grid-column: 5;
        -ms-grid-row: 6;
      }
      &:nth-of-type(29) {
        -ms-grid-column: 7;
        -ms-grid-row: 6;
      }
      &:nth-of-type(30) {
        -ms-grid-column: 9;
        -ms-grid-row: 6;
      }
      &:nth-of-type(31) {
        -ms-grid-column: 1;
        -ms-grid-row: 7;
      }
      &:nth-of-type(32) {
        -ms-grid-column: 3;
        -ms-grid-row: 7;
      }
      &:nth-of-type(33) {
        -ms-grid-column: 5;
        -ms-grid-row: 7;
      }
      &:nth-of-type(34) {
        -ms-grid-column: 7;
        -ms-grid-row: 7;
      }
      &:nth-of-type(35) {
        -ms-grid-column: 9;
        -ms-grid-row: 7;
      }
      &:nth-of-type(36) {
        -ms-grid-column: 1;
        -ms-grid-row: 8;
      }
      &:nth-of-type(37) {
        -ms-grid-column: 3;
        -ms-grid-row: 8;
      }
      &:nth-of-type(38) {
        -ms-grid-column: 5;
        -ms-grid-row: 8;
      }
      &:nth-of-type(39) {
        -ms-grid-column: 7;
        -ms-grid-row: 8;
      }
      &:nth-of-type(40) {
        -ms-grid-column: 9;
        -ms-grid-row: 8;
      }
      &:nth-of-type(41) {
        -ms-grid-column: 1;
        -ms-grid-row: 9;
      }
      &:nth-of-type(42) {
        -ms-grid-column: 3;
        -ms-grid-row: 9;
      }
      &:nth-of-type(43) {
        -ms-grid-column: 5;
        -ms-grid-row: 9;
      }
      &:nth-of-type(44) {
        -ms-grid-column: 7;
        -ms-grid-row: 9;
      }
      &:nth-of-type(45) {
        -ms-grid-column: 9;
        -ms-grid-row: 9;
      }
      &:nth-of-type(46) {
        -ms-grid-column: 1;
        -ms-grid-row: 10;
      }
      &:nth-of-type(47) {
        -ms-grid-column: 3;
        -ms-grid-row: 10;
      }
      &:nth-of-type(48) {
        -ms-grid-column: 5;
        -ms-grid-row: 10;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .medewerkers-item__image {
    width: 45% !important;
    height: 45% !important;
  }
}