.layout-sidebar-right {
  > * {
    &:nth-child(1) {
      margin-bottom: 2rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .layout-sidebar-right {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 6fr 1.875rem 6fr;

    > * {
      &:nth-child(1) {
        margin-bottom: 0;
        -ms-grid-column: 1;
        -ms-grid-row: 1;

        display: flex;
        flex-direction: column;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;


        display: flex;
        flex-direction: column;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .layout-sidebar-right {
    grid-template-columns: 8fr 4fr;

    -ms-grid-columns: 8fr 1.875rem 4fr;

    .layout__sidebar {
      //width: 100%;
      //margin-left: 10%;
    }
  }
}