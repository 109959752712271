.breadcrumb {

  li {
    @extend .breadcrumb-item;
    text-transform: uppercase;

    a {
      color: $green;
    }

    &.last {
      @extend .active;
    }
  }
}