.gallery {
    margin: 0 -1rem;
    @include clearfix;
    overflow: hidden;

    a {
        display: inline-block;
        padding: 1rem;
        float: left;
        position: relative;
        width: 50%;
        text-decoration: none;

        figure {

            &:after {
                content: "\E00B";
                font-family: "springschans-icons";
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                line-height: 1;
                text-decoration: inherit;
                text-rendering: optimizeLegibility;
                text-transform: none;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;

                font-smoothing: antialiased;
                background-color: rgba($primary, 0.75);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

                -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
                transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

                opacity: 0;
            }
        }
        &:hover {
            figure {
                &:after {
                    font-size: calculateRem(65px);
                    opacity: 1;
                }
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.gallery {
    &.one-image {
        a {
            width: 50%;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }
        }
    }
    &.two-image {
        a {
            width: 50%;
        }
    }
    &.three-image {
        a {
            width: 50%;
            &:nth-child(3) {
                width: 100%;
            }
        }
    }
    &.more {
        a {
            width: 50%;
        }
    }
}