.news-list-wrapper {
  > * {
    margin-bottom: 2rem;
  }
  &--home {
    > * {
      &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8) {
        display: none;
      }
    }
  }
}

.news-list-item {
  position: relative;

  .news-item {
    display: block!important;
    background-color: $white;

    .block-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 0.25rem;
    }

    header {
      position: relative;

      .news-item__image {
        overflow: hidden;
        figure {
          @include transition(0.3s);
        }
      }
    }

    section {
      padding: 1rem 1rem;
    }

    .post-date {
      @include fluid-type($min_width, $max_width, (15px/1.6), 15px, 1.25, 1.25);
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  a {
    color: $body-color;
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 30px rgba(0,0,0,.15);
      opacity: 0;
      border-radius: $border-radius;
      @include transition(0.3s);
    }

    &:hover {
      figure {
        transform: scale(1.05);
      }
      &:after {
        opacity: 1;
      }
    }
  }

  &.highlighted {
    display: flex;
    align-items: center;
    padding: 1.875rem;
    background-color: $blue;
    @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 1.1, 1.1);
    font-weight: 200;

    a {
      color: $white;
    }

    span:after {
      content: "›";
      margin-left: 0.35rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .news-list-wrapper {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    &:not(.news-list-wrapper--home) > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }

      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }

      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }

      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }

      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }

      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }

      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 6;
      }

      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 6;
      }
    }

    &--home {
      > * {
        &:nth-of-type(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-of-type(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-of-type(3) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
          margin-bottom: 0;
          display: block;
        }

        &:nth-of-type(4) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }

        &:nth-of-type(5) {
          -ms-grid-column: 1;
          -ms-grid-row: 5;
        }

        &:nth-of-type(6) {
          -ms-grid-column: 3;
          -ms-grid-row: 5;
        }

        &:nth-of-type(7) {
          -ms-grid-column: 1;
          -ms-grid-row: 7;
        }

        &:nth-of-type(8) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }

        &:nth-of-type(9) {
          -ms-grid-column: 1;
          -ms-grid-row: 9;
        }

        &:nth-of-type(10) {
          -ms-grid-column: 3;
          -ms-grid-row: 9;
        }

        &:nth-of-type(11) {
          -ms-grid-column: 1;
          -ms-grid-row: 11;
        }

        &:nth-of-type(12) {
          -ms-grid-column: 3;
          -ms-grid-row: 11;
        }

        &.highlighted {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }
      }
    }
  }

  .news-list-item {
    &.highlighted {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .news-list-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    &:not(.news-list-wrapper--home) > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
    }

    &--home {
      > * {
        &:nth-of-type(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-of-type(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-of-type(3) {
          -ms-grid-column: 5;
          -ms-grid-row: 1;
          margin-bottom: 2rem;
        }

        &:nth-of-type(4) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
          display: block;
          margin-bottom: 0;
        }

        &:nth-of-type(5) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
          display: block;
          margin-bottom: 0;
        }

        &:nth-of-type(6) {
          -ms-grid-column: 5;
          -ms-grid-row: 2;
        }

        &:nth-of-type(7) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }

        &:nth-of-type(8) {
          -ms-grid-column: 5;
          -ms-grid-row: 2;
        }

        &:nth-of-type(9) {
          -ms-grid-column: 5;
          -ms-grid-row: 3;
        }

        &:nth-of-type(10) {
          -ms-grid-column: 1;
          -ms-grid-row: 4;
        }

        &:nth-of-type(11) {
          -ms-grid-column: 3;
          -ms-grid-row: 4;
        }

        &:nth-of-type(12) {
          -ms-grid-column: 5;
          -ms-grid-row: 4;
        }

        &.highlighted {
          -ms-grid-column: 5;
          -ms-grid-row: 2;
        }
      }
    }
  }

  .news-list-item {
    .news-item {
      section {
        padding: 1.25rem 1.875rem;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .news-list-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    &:not(.news-list-wrapper--home) > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
    }

    &--home {
      > * {
        &:nth-of-type(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-of-type(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-of-type(3) {
          -ms-grid-column: 5;
          -ms-grid-row: 1;
        }

        &:nth-of-type(4) {
          -ms-grid-column: 7;
          -ms-grid-row: 1;
          margin-bottom: 2rem;
        }

        &:nth-of-type(5) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
          margin-bottom: 0;
        }

        &:nth-of-type(6) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
          display: block;
          margin-bottom: 0;
        }

        &:nth-of-type(7) {
          -ms-grid-column: 5;
          -ms-grid-row: 2;
          display: block;
          margin-bottom: 0;
        }

        &:nth-of-type(8) {
          -ms-grid-column: 7;
          -ms-grid-row: 2;
        }

        &:nth-of-type(9) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }

        &:nth-of-type(10) {
          -ms-grid-column: 3;
          -ms-grid-row: 3;
        }

        &:nth-of-type(11) {
          -ms-grid-column: 5;
          -ms-grid-row: 3;
        }

        &:nth-of-type(12) {
          -ms-grid-column: 7;
          -ms-grid-row: 3;
        }

        &.highlighted {
          -ms-grid-column: 7;
          -ms-grid-row: 2;
        }
      }
    }
  }
}