.nav-container {
  background-color: $blue;
  width: 100%;
  z-index: 99;
  transition: 0.3s;

  .topbar {
    background-color: $white;
    height: 60px;

    .topbar-wrapper {
      grid-column: 2;
      -ms-grid-column: 2;
      position: relative;
      justify-content: flex-end;
      display: flex;
      align-items: center;

      a.text-link {
        border-bottom: 1px solid $blue;
        &:before {
          display: none;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .navbar {
    grid-template-columns: 1fr 12fr 1fr;

    -ms-grid-columns: 1fr 12fr 1fr;

    position: relative;

    .navbar-inner {
      grid-column: 2;
      grid-row: 1;
      -ms-grid-column: 2;
      -ms-grid-row: 1;
      justify-content: flex-start;
      display: flex;
      align-items: center;
    }
  }

  .navbar-brand {
    margin-top: -60px;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    font-size: 0;
    line-height: inherit;
    z-index: 0;
    img {
      width: auto;
      height: 50px;
    }
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-flex;
    align-items: center;

    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, (17.5px/1.25), 17.5px, 1, 1);
      color: $white;
      font-weight: 300;
      line-height: 96px;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.3s;
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        margin-left: 0.25rem;
        color: $white;
        &:before {
          margin-top: -50%;
          width: 8px;
          height: 8px;
          border-left: 8px solid $white;
          border-bottom: 8px solid transparent;
          transition: 0.3s;
        }
      }
    }

    //mega submenu
    > .submenu {
      background-color: $white;
      position: absolute;
      top: 100%;
      left: -1rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      box-shadow: 0 0 2px 2px rgba(0,0,0,.10);

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          margin: 0.75rem 0;
          width: 280px;
          text-align: left;

          > li {
            display: block;
            position: relative;
            padding: 0.25rem 2rem;
            break-inside: avoid;

            a {
              position: relative;
              padding: 0.5rem 0;
              @include fluid-type($min_width, $max_width, (17.5px/1.25), 17.5px, 1, 1);
              color: $body-color;
              font-weight: 300;
              text-decoration: none;
              transition: 0.3s;
            }

            &.active, &:hover {
              a {
                color: $blue;
                &:before {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        color: $yellow;
        font-weight: 700;
      }
    }

    //open submenu
    &:hover {
      .custom-arrow {
        &:before {
          border-left-color: $yellow;
        }
      }
      > a:not(.btn) {
        color: $yellow;

      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.nav-second {
  display: none;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 0 0 auto;

  > li {
    display: inline-flex;
    align-items: center;

    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, (17.5px/1.25), 17.5px, 1, 1);
      color: $white;
      font-weight: 300;
      line-height: 96px;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.3s;

      i {
        @include fluid-type($min_width, $max_width, (30px/1.25), 30px, 1, 1);
        vertical-align: middle;
        display: inline-flex;
        margin-right: 0.5rem;
      }
    }

    &:hover {
      > a:not(.btn) {
        color: $yellow;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-second {
    > li {
      padding: 0.5rem 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {

  .nav-container {
    &:not(.sticky) {
      position: absolute;
    }
  }


  ul.nav-desktop, .nav-second {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    position: fixed;
    top: 0;
    left: 0;

    &.sticky {
      box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);

      .nav-topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 0.5rem 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  .nav-container {
    .topbar {
      height: 114px;
      display: grid;
      grid-template-columns: 1fr 12fr 1fr;

      display: -ms-grid;
      -ms-grid-columns: 1fr 12fr 1fr;
    }
    .navbar-brand {
      margin-top: -107px;
      margin-right: 2rem;
      margin-left: 0;
      img {
        width: 245px;
        height: 167px;
      }
    }
    .navbar {
      display:grid;
      display: -ms-grid;
    }
  }

  ul.nav-desktop {

    > li {
      padding: 1.35rem 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }

  ul.nav-second {
    display: flex;
    > li {
      padding: 1rem 1rem;
    }
  }
}
