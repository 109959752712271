.page-loader {
  background-color: rgba($white,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  @include transition(all, 0.3s);

  &__wrapper {
    background-color: rgba($black,0.05);
    display: flex;
    position: relative;
    width: 100px;
    height: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      border: 6px solid $primary;
      border-radius: 50%;
      animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $secondary transparent transparent transparent;
    }

    span {
      text-align: center;
      width: 100px;
      color: $secondary;
      font-size: 24px;
      animation: search-icon-animation 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }

  &__wrapper div:nth-child(1) {
    animation-delay: -0.45s;
  }

  img {
    margin: auto;
    max-width: 64px;
    width: 64px;
  }

  @keyframes search-icon-animation {
    0% {
      transform: scale(0.5) translate(-100px, 20px);
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      transform: scale(0.5) translate(100px, 20px);
      opacity: 0;
    }
  }

  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

body.show-loader {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  .page-loader {
    opacity: 1;
    z-index: 99;
  }
}