ul.list--share {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    padding: 0;
    margin-right: 8px;
    text-align: center;
    float: left;

    &:before {
      display: none;
    }

    a {
      color: $white;
      text-decoration: none;
      i {
        background-color: $blue;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 18px;
        border: 3px solid transparent;
        @include transition(0.3s);
        backface-visibility: hidden;

        &[class$="facebook"] {
          background-color: $facebook-color;
        }
        &[class$="twitter"] {
          background-color: $twitter-color;
        }
        &[class$="instagram"] {
          background-color: $instagram-color;
        }
        &[class$="linkedin"] {
          background-color: $linkedin-color;
        }
      }
      &:hover {
        i {
          transform: scale(1.12);

          &[class$="facebook"] {
            border: 4px solid lighten($facebook-color, 10%);
          }

          &[class$="twitter"] {
            border: 4px solid lighten($twitter-color, 10%);
          }

          &[class$="instagram"] {
            border: 4px solid lighten($instagram-color, 10%);
          }

          &[class$="linkedin"] {
            border: 4px solid lighten($linkedin-color, 10%);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .share {
    ul {
      li {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .share {
    ul {
      li {
        display: inline-block;
      }
    }
  }
}
