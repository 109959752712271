$white:           #fff;
$grey:            #898f97;
$black:           #000;

$green:           #39b590;
$red:             #e6542d;
$pink:            #d72581;
$blue:            #306798;
$blue-dark:       #2e3947;
$yellow:          #ffc822;

$primary:         $blue;
$secondary:       $yellow;

$body-color:      #434444;
$headings-color:  $blue-dark;

$facebook-color:  $blue;
$twitter-color:   #1DA1F2;
$instagram-color: #d9436c;
$linkedin-color:  $blue-dark;

$springschans-colors:(
        yellow: $yellow,
        red: $red,
        pink: $pink,
        blue: $blue,
        blue-dark: $blue-dark,
        green: $green
);
