main, .section {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  margin-bottom: 2rem;
  > * {
    &:nth-of-type(1) {
      grid-column: 2;
      -ms-grid-column: 2;
    }
  }

  &-content {
    grid-template-columns: 2fr 10fr 2fr;
    -ms-grid-columns: 2fr 10fr 2fr;
  }
}