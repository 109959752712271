body {
  background-color: #f0f1f2;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

.sidebar {
  position: relative;
}

@each $name, $color in $springschans-colors {
  .text-#{$name} {
    color: $color;
  }
}

.text-label {
  position: absolute;
  left: 0;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: block;
  transform-origin: center;
  top: 50%;
  z-index: 1;
  height: 26px;
  overflow: hidden;

  span {
    padding: 0 1rem;
    @include fluid-type($min_width, $max_width, (15px/1.2), 15px, 1.4, 1.4);
    text-transform: uppercase;
  }
}

@each $name, $color in $springschans-colors {
  .text-label-#{$name} {
    span {
      background-color: $color;
      color: $white;
    }
  }
}


.text-link {
  color: $blue;
  text-decoration: none;

  &:before {
    content: "›";
    @include fluid-type($min_width, $max_width, (22px/1.6), 22px, 1.1, 1.1);
    margin-right: 0.35rem;
  }
}

a.text-link {
  border-bottom: 1px solid transparent;
  @include transition(0.3s);
  &:hover {
    border-bottom: 1px solid $blue;
  }
}

table {
  border-spacing: 0;
}

.site-content {

  &__back {
    display: inline-block;
    margin-bottom: 1rem;
    @include fluid-type($min_width, $max_width, (15px/1.6), 15px, 1.25, 1.25);
    font-weight: 200;
    text-transform: uppercase;
  }

  &__date {
    margin-bottom: 1rem;
    @include fluid-type($min_width, $max_width, (15px/1.6), 15px, 1.25, 1.25);
    font-weight: 200;
    text-transform: uppercase;
  }

  a:not(.page-link) {
    color: $blue;
    font-weight: 600;
    text-decoration: underline;
  }

  ul:not(.specs):not(.pagination) {
    padding-left: 1rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    list-style: none;

    li {
      position: relative;
      &:before {
        position: absolute;
        content: "›";
        margin-left: -1rem;
        @include fluid-type($min_width, $max_width, (28px/1.6), 28px, 0.8, 0.8);
        color: $blue;
      }
    }
  }
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($green, 0.95);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.padding-top--normal {
  padding-top: 2rem;
}

.padding-bottom--normal {
  padding-bottom: 2rem;
}

.padding-top--large {
  padding-top: 2rem;
}

.padding-bottom--large {
  padding-bottom: 2rem;
}

.padding-top--larger {
  padding-top: 3rem;
}

.padding-bottom--larger {
  padding-bottom: 3rem;
}

.margin-top--normal {
  margin-top: 2rem;
}

.margin-bottom--normal {
  margin-bottom: 2rem;
}

.margin-offset--normal {
  margin-top: -2rem;
}

.margin-top--large {
  margin-top: 2rem;
}

.margin-bottom--large {
  margin-bottom: 2rem;
}

.margin-top--larger {
  margin-top: 3rem;
}

.margin-bottom--larger {
  margin-bottom: 3rem;
}

@include media-breakpoint-up(md) {
  .padding-top--normal {
    padding-top: 4rem;
  }

  .padding-bottom--normal {
    padding-bottom: 4rem;
  }

  .padding-top--large {
    padding-top: 6rem;
  }

  .padding-bottom--large {
    padding-bottom: 6rem;
  }

  .padding-top--larger {
    padding-top: 8rem;
  }

  .padding-bottom--larger {
    padding-bottom: 8rem;
  }

  .margin-top--normal {
    margin-top: 4rem;
  }

  .margin-bottom--normal {
    margin-bottom: 4rem;
  }

  .margin-offset--normal {
    margin-top: -4rem;
  }

  .margin-top--large {
    margin-top: 6rem;
  }

  .margin-bottom--large {
    margin-bottom: 6rem;
  }

  .margin-offset--large {
    margin-top: -6rem;
  }

  .margin-top--larger {
    margin-top: 8rem;
  }

  .margin-bottom--larger {
    margin-bottom: 8rem;
  }

  .margin-offset--larger {
    margin-top: -8rem;
  }

  .layout-small {
    grid-template-columns: 5fr 8fr 5fr;
    -ms-grid-columns: 5fr 8fr 5fr;
  }

  main {
    margin-bottom: 4rem;
  }
}

.site-submenu {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter site-submenu__body right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  border-top: 1px solid #898f97;
  border-bottom: 1px solid #898f97;

  &__body {
    grid-area: site-submenu__body;

    -ms-grid-column: 2;
    -ms-grid-row: 1;
    min-width: 100%
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
      &:not(:last-child):after {
        content: "/";
      }
      a {
        display: inline-block;
        padding: 0.5rem 0.75rem;
        @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.4, 1.4);
        color: $body-color;
        font-weight: 300;
        text-decoration: none;
      }
      &.active {
        a {
          font-weight: 800;
        }
      }
      &:hover {
        a {
          color: $primary;
        }
      }
    }
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}

//custom arrow
.custom-arrow {
  position: relative;
  display: flex;
  min-width: 10px;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-left: 10px solid $primary;
    border-bottom: 10px solid transparent;
    transform: translateY(-3px) rotate(-135deg);
  }
}

#scroll-to-top {
  position: fixed;
  right: 0;
  bottom: 0;
  display: none;
  background-color: #0f67bc;
  height: 56px;
  width: 56px;
  border: 0;
  z-index: 9;
  cursor: pointer;

  span {
    height: 100%;
    width: 100%;
    &:before {
      border-left: 10px solid $white;
      transform: translateY(4px) rotate(45deg);
    }
  }
}

@include media-breakpoint-up(lg) {
  .layout--max-width {
    margin: 0 auto;
    width: 100%;
    max-width: 60%;
  }
}

@include media-breakpoint-up(xl) {
  #scroll-to-top {
    display: none!important;
  }
}