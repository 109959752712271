ul.list--default, ul.list {
  padding: 0;
  margin: 0 0 1.75rem;
  list-style: none;
  li {
    margin-bottom: 0.5rem;
  }
}

ul.list--check {
  padding-left: 1.25rem;
  margin: 0 0 1.75rem;
  @include fluid-type($min_width, $max_width, (14px/1.25), 14px, (1.6/1.25), 1.6);
  font-weight: bold;
  text-transform: uppercase;
  li {
    position: relative;
    margin-bottom: 0.65rem;
    &:before {
      position: absolute;
      @extend .springschans-icons;
      @extend .springschans-icons-check;
      margin-left: -1.25rem;
      color: $primary;
    }
  }
}

ul.list--inline {
  margin: 0 0 1.75rem;
  li {
    display: inline-block;
    margin: 0 0.2rem 0.6rem;
  }
}

ul.list--quicklinks {
  padding: 0;
  margin: 0 0 1.75rem;
  text-align: center;

  li {
    a {
      background-color: transparent;
      border-width: 2px;
      border-style: solid;
      @include transition(0.3s);
      &:hover {
        transform: scale(1.05);
      }
    }

  }

  li:nth-child(6n + 1) a {
    border-color: $yellow;
    &:hover {
      background-color: $yellow;
      color: $white;
    }
  }

  li:nth-child(6n + 2) a {
    border-color: $red;
    &:hover {
      background-color: $red;
      color: $white;
    }
  }

  li:nth-child(6n + 3) a {
    border-color: $pink;
    &:hover {
      background-color: $pink;
      color: $white;
    }
  }

  li:nth-child(6n + 4) a {
    border-color: $blue;
    &:hover {
      background-color: $blue;
      color: $white;
    }
  }

  li:nth-child(6n + 5) a {
    border-color: $blue-dark;
    &:hover {
      background-color: $blue-dark;
      color: $white;
    }
  }

  li:nth-child(6n + 6) a {
    border-color: $green;
    &:hover {
      background-color: $green;
      color: $white;
    }
  }
}

//$i: 0;
//@each $name, $color in $springschans-colors {
//  $i: $i + 1;
//  ul.list--quicklinks li:nth-child(#{$i}) a {
//    background-color: transparent;
//    border-color: $color;
//    border-width: 2px;
//    border-style: solid;
//
//  }
//}