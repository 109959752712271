.three-c-layout {

  > * {
    &:nth-child(1) {
      -ms-grid-column: 1;
    }

    &:nth-child(2) {
      -ms-grid-column: 3;
    }

    &:nth-child(3) {
      -ms-grid-column: 5;
    }

    &:nth-child(4) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-child(5) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-child(6) {
      -ms-grid-column: 5;
      -ms-grid-row: 3;
    }
  }
}

.four-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

.five-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

.six-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-up(sm) {

  .four-c-layout {
    display: grid;
    grid-template-columns: minmax(0, 6fr) minmax(0, 6fr);
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
    }
  }

  .five-c-layout {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-child(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-child(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-child(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-child(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 6;
      }
      &:nth-child(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 6;
      }
    }
  }

  .six-c-layout {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .two-c-layout {
    display: grid;
    grid-template-columns: minmax(0, 6fr) minmax(0, 6fr);
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 6fr 1.875rem 6fr;

    height: 100%;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      &:nth-child(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }

      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-child(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
    }
  }

  .three-c-layout {
    display: grid;
    grid-template-columns: minmax(0, 6fr) minmax(0, 6fr);
    grid-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: auto;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-child(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
    }
  }

  .five-c-layout {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
        margin-bottom: 0;
      }
      &:nth-child(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
        margin-bottom: 0;
      }
      &:nth-child(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-child(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-child(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-child(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .three-c-layout {
    grid-template-columns: minmax(0, 6fr) minmax(0, 6fr) minmax(0, 6fr);
    grid-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
    }
  }

  .five-c-layout {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      margin-bottom: 0;
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-child(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-child(9) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-child(10) {
        -ms-grid-column: 9;
        -ms-grid-row: 2;
      }
      &:nth-child(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(13) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-child(14) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
      &:nth-child(15) {
        -ms-grid-column: 9;
        -ms-grid-row: 3;
      }
    }
  }

  .six-c-layout {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-child(6) {
        -ms-grid-column: 11;
        -ms-grid-row: 1;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .four-c-layout {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-child(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(11) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-child(12) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
    }
  }
}