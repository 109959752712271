@font-face {
	font-family: "springschans-icons";
	src: url('../fonts/springschans-icons.eot');
	src: url('../fonts/springschans-icons.eot?#iefix') format('eot'),
		url('../fonts/springschans-icons.woff2') format('woff2'),
		url('../fonts/springschans-icons.woff') format('woff'),
		url('../fonts/springschans-icons.ttf') format('truetype'),
		url('../fonts/springschans-icons.svg#springschans-icons') format('svg');
}

.springschans-icons:before {
	font-family: "springschans-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.springschans-icons-arrow-next:before {
	content: "\E001";
}

.springschans-icons-check:before {
	content: "\E002";
}

.springschans-icons-double-arrow:before {
	content: "\E003";
}

.springschans-icons-email:before {
	content: "\E004";
}

.springschans-icons-facebook:before {
	content: "\E005";
}

.springschans-icons-instagram:before {
	content: "\E006";
}

.springschans-icons-linkedin:before {
	content: "\E007";
}

.springschans-icons-marker:before {
	content: "\E008";
}

.springschans-icons-phone:before {
	content: "\E009";
}

.springschans-icons-pinterest:before {
	content: "\E00A";
}

.springschans-icons-search:before {
	content: "\E00B";
}

.springschans-icons-times:before {
	content: "\E00C";
}

.springschans-icons-twitter:before {
	content: "\E00D";
}

.springschans-icons-vimeo:before {
	content: "\E00E";
}

.springschans-icons-whatsapp:before {
	content: "\E00F";
}

.springschans-icons-youtube:before {
	content: "\E010";
}
