.member-list-wrapper {
  > * {
    margin-bottom: 2rem;
  }
}

.member {
  &__name {
    margin-top: 1.5rem;
    font-weight: 700;
    &:before {
      content: "—";
      margin-right: 0.25rem;
    }
  }
  &__image {
    padding: 0 1.875rem;
    margin-bottom: 1.875rem;

    figure {
      position: relative;
      @extend .springschans-icons;
      @extend .springschans-icons-search;
      border: 4px solid $green;
      &:before {
        background-color: rgba($green,0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fluid-type($min_width, $max_width, (32px/1.5), 32px, 1/1.5, 1);
        color: $white;
        opacity: 0;
        z-index: 1;
        @include transition(0.3s);
        backface-visibility: hidden;
      }
    }
  }
  &__description {
    padding: 0 1.875rem;
    text-align: center;
  }
  a {
    color: $body-color;
    text-decoration: none;
    &:hover {
      figure:before {
        opacity: 1;
        transform: scale(1.15);
      }
    }
  }
  &:nth-child(even){
    .member__image {
      figure {
        border: 4px solid $blue;
        &:before {
          background-color: rgba($blue,0.6);
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .member-list-wrapper {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 7;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 7;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 9;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 9;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 11;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 11;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .member-list-wrapper {
    display: grid;
    grid-template-columns: 6fr 6fr 6fr;
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .member-list-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
      &:nth-of-type(13) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-of-type(14) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-of-type(15) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
      &:nth-of-type(16) {
        -ms-grid-column: 7;
        -ms-grid-row: 4;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .member-list-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 9;
        -ms-grid-row: 2;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(13) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(14) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
      &:nth-of-type(15) {
        -ms-grid-column: 9;
        -ms-grid-row: 3;
      }
    }
  }
}