.image-header {
  min-height: 300px;
  position: relative;
  z-index: 0;

  &-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__content {
    display:grid;
    grid-template-columns: 1fr 12fr 1fr;

    display: -ms-grid;
    -ms-grid-columns: 1fr 12fr 1fr;

    padding-bottom: 5vw;

    > * {
      grid-column: 2;
      -ms-grid-column: 2;
    }
  }

  &__cta {
    z-index: 9;
  }

  &__background {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: hwb(214deg 18% 72% / 70%);
      z-index: -1;
    }

    .bg-progressive {
      z-index: -1;
    }
  }

  &__text {
    margin-bottom: 80px;

    color: $white;
  }

  &__title {
    max-width: 600px;
    color: $white;
    z-index: 9;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      padding-left: 10px;
      @include fluid-type($min_width, $max_width, (32.5px/1.5), 32.5px, 2/1.5, 2);
      color: $white;
      font-weight: 200;
      margin-bottom: 1rem;
    }

    mark {
      background-color: #f4f4f4;
      color: $body-color;
      box-shadow: 10px 0 0 #f4f4f4, -10px 0 0 #f4f4f4;
    }
  }

  &__images {
    padding: 2rem 0;
    margin: 0 auto;
    display: grid;
    display: -ms-grid;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
    grid-template-columns: 3fr 6fr 3fr;
    -ms-grid-columns: 3fr 6fr 3fr;

    > * {
      position: relative;
      &:nth-of-type(1) {
        grid-column: 2;
        grid-row: 1 / span 2;
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
      }
      &:nth-of-type(2) {
        grid-column: 3;
        grid-row: 1;
        -ms-grid-column: 3;
        -ms-grid-row: 1;

        margin-top: 4%;
        max-width: 70%;
      }
      &:nth-of-type(3) {
        grid-column: 3;
        grid-row: 2 / span 1;
        -ms-grid-column: 3;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;

        margin-left: 6%;
        max-width: 90%;
      }
    }
  }

  &__video {
    &-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      height: 100%;
      border-radius: 50%;
      z-index: 1;
      @include fluid-type($min_width, $max_width, (100px/2), 100px, 2/1.5, 2);
      color: #eeb300;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;

      &__icon {
        i {
          display: block;
          transition: 0.3s;
        }
      }

      &__title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          background-color: #f4f4f4;
          box-shadow: 10px 0 0 #f4f4f4, -10px 0 0 #f4f4f4;
          max-width: 600px;
          margin: 6rem 0 0;
          z-index: 9;
          @include fluid-type($min_width, $max_width, (24px/2), 24px, 2/1.5, 2);
          color: $body-color;
          text-decoration: none;
          font-weight: 200;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      &:hover {
        background-color: rgba(#000000,0.2);
        .image-header__video-overlay__icon i {
          transform: perspective(1px) scale(1.06);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-header {
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__title {
      max-width: 200px;
    }
    &__images {
      width: 54vw;
    }
  }
}


@include media-breakpoint-up(lg) {
  .image-header {
    margin-top: 60px;
    &__video {
      &-overlay {
        &__title {
          span {
            margin: 9rem 0 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .image-header {
    margin-top: 177px;
    &__title {
      max-width: 600px;
    }
  }
}
