nav {
    ul.pagination {
        margin: 1.5rem 0;
    }
    li.page-item {
        margin: 0 0.25rem;
        a {
            line-height: 18px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
        }
        &.previous, &.next {
            margin: 0 1rem;
        }
        &.previous {
            transform: rotate(-135deg);
        }
        &.next {
            transform: rotate(45deg);
        }
        a.page-link {
            display: flex;
            justify-content: center;
            height: 36px;
            width: 36px;
            border-radius: 50%!important;
            position: relative;
            vertical-align: middle;
            .custom-arrow {
                &:before {

                    transform: translate(-1px, 1px) rotate(90deg);
                    border-left: 10px solid $body-color;
                }
            }
        }
        &.disabled {
            a.page-link {
                .custom-arrow {
                    &:after {
                        background-color: $white;
                    }
                    &:before {
                        background-color: $gray-300;
                    }
                }
            }
        }
    }
}

a:hover {
    .custom-arrow {
        &:before {
            //border-left: 2px solid $white;
            //border-bottom: 2px solid $white;
        }
    }
}