.quote {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__image {
    flex: 1;
    margin: 0 1rem;
  }

  &__description {
    flex: 4;
    margin: 0 1rem;
    @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.25, 1.25);
    color: $blue-dark;
    font-weight: 300;
  }

  &__text {
    &:before, &:after {
      position: absolute;
      height: 10px;
    }
    &:before {
      content: "“";
      font-size: 22px;
      transform: translate(-8px, 0);
    }
    &:after {
      content: "”";
      font-size: 22px;
    }
  }

  &__name {
    margin-top: 1.5rem;
    font-weight: 700;
    &:before {
      content: "—";
      margin-right: 0.25rem;
    }
  }
}