.video-container {
  position: relative;
  width: 100%;
  margin-bottom: 1.75rem;
  padding-top: calc(8.7/16*100%); /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  transition: .45s cubic-bezier(.49, 1, .22, 1) 0ms;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.video {
  &__wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40%;
    height: 100%;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      width: 90%;
      height: 60vw;
    }

    @include media-breakpoint-up(xl) {
      width: 50%;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:hover {
      .video__placeholder {
        opacity: 0.8;
      }
    }
  }

  &__placeholder {
    height: 100%;
    transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      width: 0;
      height: 0;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 40px solid $white;
      transform: translate(-16px,-30px);
      pointer-events: none;

      @include media-breakpoint-down(lg) {
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 20px solid $white;
        transform: translate(-8px,-15px);
      }
    }

    i {
      font-size: 8em;
      color: fade-out($secondary,0.2);
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        font-size: 5em;
      }
    }
  }

  &__container {
    background: rgba($black, 0.65);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    overflow: hidden;
    transition: .45s cubic-bezier(.49, 1, .22, 1) 0ms;

    &.video__container--open {
      visibility: visible;
      opacity: 1;

      .video__iframe {
        transform: scale(0.95);
      }
    }
  }

  &__iframe {
    transform: scale(0.85);
    transition: .45s cubic-bezier(.49, 1, .22, 1) 0ms;
    width: 100%;
    max-width: 100vmin;
    &::before {
      content: "";
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: calc(9/16*100%); /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    &::after { /* to clear float */
      content: "";
      display: table;
      clear: both;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.video__close {
  position: absolute;
  top: -23px;
  right: -23px;
  z-index: 1;
  display: flex;
  flex-direction: row;

  &__icon {
    position: relative;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;

    &-background {
      position: absolute;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 0;
      color: $secondary;
    }

    &-icon {
      color: $text-color;
      cursor: pointer;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        z-index: 1;
      }

      i {
        cursor: pointer;
        display: flex;
        background: $secondary;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        color: $body-color;
        align-items: center;
        justify-content: center;
        font-size: 140%;
        vertical-align: middle;
        line-height: 48px;
        min-width: 48px;
        transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
        transform: scale(0.86) translate3d(0,0,0);
        backface-visibility: hidden;
      }

      &:focus,
      &:hover {
        color: $text-color;
        i {
          transform: scale(1) translate3d(0,0,0);
        }
      }
    }
  }
}

html.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}


